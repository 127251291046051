exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-de-jsx": () => import("./../../../src/pages/contact.de.jsx" /* webpackChunkName: "component---src-pages-contact-de-jsx" */),
  "component---src-pages-contact-en-jsx": () => import("./../../../src/pages/contact.en.jsx" /* webpackChunkName: "component---src-pages-contact-en-jsx" */),
  "component---src-pages-customised-order-de-jsx": () => import("./../../../src/pages/customised-order.de.jsx" /* webpackChunkName: "component---src-pages-customised-order-de-jsx" */),
  "component---src-pages-customised-order-en-jsx": () => import("./../../../src/pages/customised-order.en.jsx" /* webpackChunkName: "component---src-pages-customised-order-en-jsx" */),
  "component---src-pages-help-desk-de-jsx": () => import("./../../../src/pages/help-desk.de.jsx" /* webpackChunkName: "component---src-pages-help-desk-de-jsx" */),
  "component---src-pages-help-desk-en-jsx": () => import("./../../../src/pages/help-desk.en.jsx" /* webpackChunkName: "component---src-pages-help-desk-en-jsx" */),
  "component---src-pages-imprint-de-jsx": () => import("./../../../src/pages/imprint.de.jsx" /* webpackChunkName: "component---src-pages-imprint-de-jsx" */),
  "component---src-pages-imprint-en-jsx": () => import("./../../../src/pages/imprint.en.jsx" /* webpackChunkName: "component---src-pages-imprint-en-jsx" */),
  "component---src-pages-index-de-jsx": () => import("./../../../src/pages/index.de.jsx" /* webpackChunkName: "component---src-pages-index-de-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-order-de-jsx": () => import("./../../../src/pages/order.de.jsx" /* webpackChunkName: "component---src-pages-order-de-jsx" */),
  "component---src-pages-order-en-jsx": () => import("./../../../src/pages/order.en.jsx" /* webpackChunkName: "component---src-pages-order-en-jsx" */),
  "component---src-pages-privacy-de-jsx": () => import("./../../../src/pages/privacy.de.jsx" /* webpackChunkName: "component---src-pages-privacy-de-jsx" */),
  "component---src-pages-privacy-en-jsx": () => import("./../../../src/pages/privacy.en.jsx" /* webpackChunkName: "component---src-pages-privacy-en-jsx" */),
  "component---src-pages-sitemap-de-jsx": () => import("./../../../src/pages/sitemap.de.jsx" /* webpackChunkName: "component---src-pages-sitemap-de-jsx" */),
  "component---src-pages-sitemap-en-jsx": () => import("./../../../src/pages/sitemap.en.jsx" /* webpackChunkName: "component---src-pages-sitemap-en-jsx" */),
  "component---src-pages-terms-of-service-de-jsx": () => import("./../../../src/pages/terms-of-service.de.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-de-jsx" */),
  "component---src-pages-terms-of-service-en-jsx": () => import("./../../../src/pages/terms-of-service.en.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-en-jsx" */),
  "component---src-pages-uptime-de-jsx": () => import("./../../../src/pages/uptime.de.jsx" /* webpackChunkName: "component---src-pages-uptime-de-jsx" */),
  "component---src-pages-uptime-en-jsx": () => import("./../../../src/pages/uptime.en.jsx" /* webpackChunkName: "component---src-pages-uptime-en-jsx" */),
  "component---src-pages-workshops-de-jsx": () => import("./../../../src/pages/workshops.de.jsx" /* webpackChunkName: "component---src-pages-workshops-de-jsx" */),
  "component---src-pages-workshops-en-jsx": () => import("./../../../src/pages/workshops.en.jsx" /* webpackChunkName: "component---src-pages-workshops-en-jsx" */),
  "component---src-templates-workshop-page-jsx": () => import("./../../../src/templates/WorkshopPage.jsx" /* webpackChunkName: "component---src-templates-workshop-page-jsx" */),
  "slice---src-components-footer-jsx": () => import("./../../../src/components/Footer.jsx" /* webpackChunkName: "slice---src-components-footer-jsx" */),
  "slice---src-components-header-jsx": () => import("./../../../src/components/Header.jsx" /* webpackChunkName: "slice---src-components-header-jsx" */)
}

