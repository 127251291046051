export default (function () {
  const existingScript = document.getElementById('linkedin-insight-script');
  if (!existingScript) {
    const linkedinScript = document.createElement('script');
    linkedinScript.type = 'text/javascript';
    linkedinScript.id = 'linkedin-insight-script';
    linkedinScript.async = true;
    linkedinScript.src =
      'https://snap.licdn.com/li.lms-analytics/insight.min.js';

    linkedinScript.onload = function () {
      const _linkedin_partner_id = '6117914';
      window._linkedin_data_partner_ids =
        window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);

      const l = window.lintrk;
      if (!l) {
        window.lintrk = function (a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
    };

    document.head.appendChild(linkedinScript);
  }
});
