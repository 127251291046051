export default (function () {
  const existingScript = document.getElementById('gtagscript');
  if (!existingScript) {
    const gtag = document.createElement('script');
    gtag.type = 'text/javascript';
    gtag.id = 'gtagscript';
    gtag.src = 'https://www.googletagmanager.com/gtag/js?id=G-DQTDCRV5MC';
    gtag.async = true;
    document.head.appendChild(gtag);
  }

  function gtag() {
    const $dataLayer = (window.dataLayer = window.dataLayer || []);
    $dataLayer.push(arguments);
  }
  window.gtag = gtag;

  gtag('js', new Date());
  gtag('config', 'G-DQTDCRV5MC', {
    anonymize_ip: true,
    allow_google_signals: true,
  });

  // send page view on initial load
  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined;
  gtag(`event`, `page_view`, { page_path: pagePath });
});
