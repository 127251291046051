export default (function () {
  const $zoho = (window.$zoho = window.$zoho || {});
  $zoho.salesiq = window.$zoho.salesiq || {
    widgetcode:
      'siqa6a7862187906f6a68bd5764c71995a0852e46d2c600742e8b21158e3bb3b0df',
    values: {},
    ready: function () {
      // eslint-disable-next-line no-console
      console.info('Zoho SalesIQ Widget is ready.');
    },
  };

  const existingScript = document.getElementById('zsiqscript');
  if (!existingScript) {
    const zoho = document.createElement('script');
    zoho.type = 'text/javascript';
    zoho.id = 'zsiqscript';
    zoho.async = true;
    zoho.src = 'https://salesiq.zohopublic.eu/widget';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(zoho, s);
  }
});
