export default (function () {
  const existingScript = document.getElementById('pagesense-script');
  if (!existingScript) {
    const pageSense = document.createElement('script');
    pageSense.type = 'text/javascript';
    pageSense.id = 'pagesense-script';
    pageSense.src =
      'https://cdn-eu.pagesense.io/js/devninas/32a54b02838d436499c7898e438735df.js';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(pageSense, s);
  }
});
