/**
 * Initialize tracking
 * @param {Object[]} treckings - of tracking objects
 * @param {any} treckings.script - Tracking script
 * @param {String} treckings.loadOn - Tracking script
 * @returns {Promise<void>}
 */
export default function initTracking(treckings) {
  const defaultCookieConsent =
    '{"necessary":false,"functional":false,"statistics":false,"marketing":false}';
  const cookieConsentStorage =
    localStorage.getItem('cookieConsent') || defaultCookieConsent;
  const cookieConsent = JSON.parse(cookieConsentStorage);

  if (Array.isArray(treckings)) {
    treckings.forEach(tracking => {
      if (tracking.loadOn === 'necessary' && cookieConsent.necessary) {
        tracking.script();
      } else if (tracking.loadOn === 'functional' && cookieConsent.functional) {
        tracking.script();
      } else if (tracking.loadOn === 'statistics' && cookieConsent.statistics) {
        tracking.script();
      } else if (tracking.loadOn === 'marketing' && cookieConsent.marketing) {
        tracking.script();
      }
    });
  }
}
